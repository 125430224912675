import React from 'react'
import './About.scss'

function About() {
  return (
    <div className='about' id='about'>
      About
    </div>
  )
}

export default About